<template>
  <div v-if="alert" class="alert-container">
    <component
      :is="componentName(alert.type)"
      :key="alert.id"
      :variant="alert.type"
      :duration="alert.duration"
      dismissible
      @dismissed="dismissToastMessage()"
    >
      <!--eslint-disable-next-line vue/no-v-html-->
      <p class="mb-0" v-html="alert.message" />
    </component>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import SuccessAlert from '~/components/alerts/SuccessAlert'
import DangerAlert from '~/components/alerts/DangerAlert'
import InfoAlert from '~/components/alerts/InfoAlert'
import WarningAlert from '~/components/alerts/WarningAlert'

export default {
  components: {
    SuccessAlert,
    DangerAlert,
    InfoAlert,
    WarningAlert,
  },
  computed: {
    ...mapGetters({
      alert: 'toastMessage',
    }),
    componentName() {
      return (name) => {
        return `${this.$capitalizeFirstLetter(name)}Alert`
      }
    },
  },
  methods: {
    ...mapActions({
      dismissToastMessage: 'dismissToastMessage',
    }),
  },
}
</script>
<style lang="scss" scoped>
.alert-container {
  display: flex;
  flex-direction: column;
  left: 50%;
  max-width: get-breakpoints('sm');
  padding: 0 15px;
  position: fixed;

  top: 10%;
  transform: translateX(-50%);
  width: 100%;

  z-index: 50;

  @include media-breakpoint-up(md) {
    max-width: get-breakpoints('md');
    width: auto;
  }

  .alert {
    border-radius: 6px;
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 0;
    margin-top: 10px;
  }
}
</style>
